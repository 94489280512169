import React, {useEffect, useState, Fragment, useContext} from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import {addAlert, openDashboard, openLook} from "../../store/actions/appActions";
import {
  DataTable,
  TextWithCaption,
  DashboardIcon,
  LookIcon,
  SimpleConfirmModal,
  ViewScheduleModal
} from "@src/components";
import {ReassignOwnerModal} from "../Modals";
import {
  Button,
  Container,
  GridRow, Icon, IconButton,
  Popover,
  IMenu,
  MoreActions,
  Tooltip,
  Skeleton,
} from "@emburse/embark-core";
import { IDataTableColumn } from "@src/interfaces/IDataTableColumn";
import {FFlags, getFlag, IFFlags, Severity} from "@src/enums";
import {
  Typography,
  Spacer
} from "@emburse/embark-core";
import { createUseStyles } from "react-jss";

import {IReportScheduleDefinition, IDashboardRef, IUserRef, ILookRef} from "../../interfaces";
import {IAlert } from "@src/interfaces";
import {IScheduleAPIContext, ScheduleAPIContext, IUsersDict, ILooksDict, IDashboardsDict} from "@components/ScheduleAPIProvider/ScheduleAPIProvider";
import {flagsSelector} from "@src/store/selector";
import cronstrue from "cronstrue";

import {DELETE_SCHEDULE, REASSIGN_OWNER, VIEW_SCHEDULE} from "../constants";
import {DeleteIcon, KeyboardArrowRightIcon, PeopleIcon} from "@emburse/embark-icons";

const useStyles = createUseStyles({
  main: {
    "& .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textLeft[data-field=destinations]": {
      alignItems: 'left !important'
    },

    "& .distributionAddress:nth-child(3)": {
      display: 'inline'
    }
  },

  manageUserBar: {
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",

    "& > span:last-child": {
      marginLeft: "auto",
    },
    "& > :first-child": {
      marginRight: "15px",
    },
  },
  sidebar: {
    width: '251px',
  },
  pageWithSidebar: {
    flex: 'auto',
    padding: '3rem'
  },
  limitWarning: {
    maxWidth: '60%'
  },
  distribution: {
    fontSize: '12px'
  },
});

/**
 * @interface UsersDict
 * Dictionary of known users referenced in the results.
 * key is user_id reported by Looker (looker_user_id)
 * value is the minimal set of attributes of the user for display
 */
interface UsersDict {
  [user_id: string]: { IUserRef }
}


/**
 * @interface DashboardsDict
 * Dictionary of known dashboards referenced in the results.
 * key is id of the dashboard
 * value is the minimal set of attributes for display
 */
interface DashboardsDict {
  [id: string]: { IDashboardRef }
}

/**
 * @interface LooksDict
 * Dictionary of known Looks referenced in the results.
 * key is id of the look
 * value is the minimal set of attributes  for display
 */
interface LooksDict {
  [id: string]: { ILookRef }
}


export interface AdminSchedulesGridProps {
  onRowClick: ( schedule: IReportScheduleDefinition ) => void;
  schedule: IReportScheduleDefinition | null;
}
const SchedulesGrid = ({onRowClick, schedule} : AdminSchedulesGridProps) => {
  const flags = useSelector(flagsSelector);
  const dispatch = useDispatch();

  const scheduleAPI = useContext<IScheduleAPIContext>(ScheduleAPIContext);
  const classes = useStyles();

  // selection/modal state
  const [selectedScheduleToView, setSelectedScheduleToView] = useState<IReportScheduleDefinition|null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");

  // list of schedules and cross-references
  const [schedules, setSchedules] = useState<IReportScheduleDefinition[]>([]);
  const [knownUsers, setKnownUsers ] =  useState<UsersDict>({});
  const [knownDashboards, setKnownDashboards ] =  useState<DashboardsDict>({});
  const [knownLooks, setKnownLooks ] =  useState<LooksDict>({});

  // Table state
  const [pageSize, setPageSize] = useState<number>(100);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [filterString, setFilterString] = useState<string>("");
  const [searchString, setSearchString] = useState<string>("");
  const [searchChanged, setSearchChanged] = useState<boolean>(false);
  const [rowCount, setRowCount] = useState<number>(0);
  const [referencedUsers, setReferencedUsers ] =  useState<IUsersDict>({});
  
  const [referencedLooks, setReferencedLooks] = useState<ILooksDict>({});
  const [referencedDashboards, setReferencedDashboards ] =  useState<IDashboardsDict>({});
  // a table state for loading
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingUsers, setIsLoadingUsers] = useState<boolean>(false);
  const [isLoadingLooks, setIsLoadingLooks] = useState<boolean>(false);
  const [isLoadingDashboards, setIsLoadingDashboards] = useState<boolean>(false);

  const closeModalCb = () => {
    setModalType("");
    setIsModalOpen(false);
  };

  function fetchReferencedUsers(schedules: IReportScheduleDefinition[]) {
    const uniqueUserIds: Set<string> = schedules.reduce((acc: Set<string>, curr: IReportScheduleDefinition) => {
      if ((curr.user_id !== undefined) && (curr.user_id !== null)) {
        acc.add(curr.user_id);
      }
      return acc;
    }, new Set<string>());

    setIsLoadingUsers(true);
    scheduleAPI.getReferencedUsers(Array.from(uniqueUserIds)).then((res) => {
      setIsLoadingUsers(false);
      setReferencedUsers(res);
    }).catch(e => {
      setIsLoadingUsers(false);
    });
  }

  function fetchReferencedLooks(schedules: IReportScheduleDefinition[]) {
    const uniqueLookIds: Set<string> = schedules.reduce((acc: Set<string>, curr: IReportScheduleDefinition) => {
      if ((curr.look_id !== undefined) && (curr.look_id !== null)) {
        acc.add(curr.look_id);
      }
      return acc;
    }, new Set<string>());

    const allLookIds: Set<string> = schedules.reduce((acc: Set<string>, curr: IReportScheduleDefinition) => {
      const firstDestination = curr.destinations[0];
      if ((firstDestination?.distribution_look_id !== undefined) && (firstDestination?.distribution_look_id !== null)) {
        acc.add(firstDestination?.distribution_look_id);
      }
      return acc;
    }, uniqueLookIds);

    setIsLoadingLooks(true);
    scheduleAPI.getReferencedLooks(Array.from(allLookIds)).then((res) => {
      setIsLoadingLooks(false);
      setReferencedLooks(res);
    }).catch(e => {
      setIsLoadingLooks(false);
    });
  }

  function fetchReferencedDashboards(schedules: IReportScheduleDefinition[]) {

    const uniqueDashboardIds: Set<string> = schedules.reduce((acc: Set<string>, curr: IReportScheduleDefinition) => {
      if ((curr.dashboard_id !== undefined) && (curr.dashboard_id !== null)) {
        acc.add(curr.dashboard_id);
      }
      return acc;
    }, new Set<string>());

    setIsLoadingDashboards(true);
    scheduleAPI.getReferencedDashboards(Array.from(uniqueDashboardIds)).then((res) => {
      setIsLoadingDashboards(false);
      setReferencedDashboards(res);
    }).catch(e => {
      setIsLoadingDashboards(false);
    });
  }

  useEffect(() => {
    fetchReferencedUsers(schedules);
    fetchReferencedLooks(schedules);
    fetchReferencedDashboards(schedules);
  }, [schedules]);

  function fetchSchedules() {
    scheduleAPI.getSchedules({}).then((res) => {
      setSchedules(res);
      setRowCount(res.length);
      setIsLoading(false);
      setSearchChanged(false);
    }).catch(e => {
      let alert: IAlert = {
        timestamp: new Date().getTime(),
        severity: Severity.ERROR,
        message: "An error occurred when fetching the schedules."
      };
      dispatch(addAlert(alert));
    });
  }

  useEffect(() => {
    setIsLoading(true);
    fetchSchedules();
  }, []);

  function handleRowClick({ row }: { row: IReportScheduleDefinition }) {
    onRowClick(row);
    flags?.[getFlag(FFlags.ViewScheduleOnScheduleAdminNameClick)] && modalType === VIEW_SCHEDULE && showScheduleDetailsModal();
  }

  const doOpenReferencedItem = (is_dashboard: boolean, ref_id: string) => {
    if (is_dashboard) {
      dispatch(openDashboard(ref_id));
    } else {
      dispatch(openLook(ref_id));
    }
  };

  const scheduleColumns: IDataTableColumn[] = [
    {
      field: "name",
      headerName: "Schedule Name",
      flex: 3,
      renderCell: (params) => {
        return (
          <div onClick={() => {
            if (flags?.[getFlag(FFlags.ViewScheduleOnScheduleAdminNameClick)]) {
              setModalType(VIEW_SCHEDULE);
            }
            
            handleRowClick(params);
            }}>
            <TextWithCaption
              text={params.row.name}
              caption={cronstrue.toString(params.row.schedule, { verbose: true }) + ", " + params.row.timezone}
            />
          </div>
        );
      },
    },
    {
      field: "dashboard_look_id",
      headerName: "Dashboard/Look",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        if (flags?.[getFlag(FFlags.ScheduleAdminShowOwnerNameDashLookName)]) {
          if (!params.row.is_dashboard && isLoadingLooks) {
            return (
                <Skeleton><Typography variant={"body1"}>{params.row.dashboard_look_id}</Typography></Skeleton>
            );
          }
  
          if (params.row.is_dashboard && isLoadingDashboards) {
            return (
                <Skeleton><Typography variant={"body1"}>{params.row.dashboard_look_id}</Typography></Skeleton>
            );
          }
  
          const referencedReport = (params.row.is_dashboard ? referencedDashboards[params.row.dashboard_look_id] : referencedLooks[params.row.dashboard_look_id]);
          let referenceName = '';
          if (referencedReport) {
            referenceName = referencedReport?.name;
          } else {
            referenceName = params.row.dashboard_look_id;
          }
  
          return (
            <>
            <Icon iconUrl={params.row.is_dashboard ? <DashboardIcon /> : <LookIcon />}/>
            <span style={{cursor: "pointer", textDecoration: "underline"}} onClick={() => doOpenReferencedItem(params.row.is_dashboard, params.row.dashboard_look_id)}>{referenceName}</span>
            </>
          );
        } else {
          return (
            <>
            <span style={{cursor: "pointer", textDecoration: "underline"}} onClick={() => doOpenReferencedItem(params.row.is_dashboard, params.row.dashboard_look_id)}>{params.row.dashboard_look_id}</span>
            </>
          );
        }
      },
    },
    {
      field: "user_id",
      headerName: "Owner",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        if (flags?.[getFlag(FFlags.ScheduleAdminShowOwnerNameDashLookName)]) {
          if (isLoadingUsers) {
            return (
                <Skeleton><Typography variant={"body1"}>{params.row.user_id}</Typography></Skeleton>
            );
          }
  
          const referencedUser = referencedUsers[params.row.user_id];
  
          if (referencedUser) {
            return (<Tooltip title={params.row.user_id}><span style={{cursor: 'default', overflow: 'hidden', textOverflow: 'ellipsis'}}>{referencedUsers[params.row.user_id]?.first_name} {referencedUsers[params.row.user_id]?.last_name}</span></Tooltip>);
          } else {
              return (<span>{params.row.user_id}</span>);
          }
        } else {
          let name = '';
          if (params.row.user) {
            name = `${params.row.user.first_name} ${params.row.user.last_name}`;
          } else {
            name = params.row.user_id;
          }
          return (
              <Tooltip title={name}>
                <span style={{cursor: 'default', overflow: 'hidden', textOverflow: 'ellipsis'}}>{name}</span>
              </Tooltip>
          );
        }
      }
    }
  ];

  flags?.[getFlag(FFlags.ScheduleAdminGridMoreActions)] ? scheduleColumns.push({
    field: "openModal",
    headerName: "",
    flex: 0.25,
    sortable: false,
    renderCell: (params) => {
      const moreActionsMenu: IMenu = {
        sections: []
      };
    
      const actionItems = moreActionsMenu.sections;
      flags?.[getFlag(FFlags.ScheduleAdminDeleteSchedule)] && actionItems?.push({
        items: [
          {
            iconText: 'Delete Schedule',
            closeOnClick: true,
            icon: DeleteIcon,
            onClick: () => {
              setIsModalOpen(true);
              setModalType(DELETE_SCHEDULE);
            }
          }
        ]
      });

      flags?.[getFlag(FFlags.ScheduleAdminReassignOwner)] && actionItems?.push({
        items: [
          {
            iconText: 'Reassign Owner',
            closeOnClick: true,
            icon: PeopleIcon,
            onClick: () => {
              setIsModalOpen(true);
              setModalType(REASSIGN_OWNER);
            }
          }
        ]
      });
      
      return (
        <MoreActions data-tag="schedulesGridMoreActions" className="da-more-actions" moreActionsMenu={moreActionsMenu}/>
      );
    },
  }) : scheduleColumns.push({
    field: "open",
    headerName: "",
    flex: 0.25,
    sortable: false,
    renderCell: (params) => {
      return (
          <>
            <Icon iconUrl={KeyboardArrowRightIcon} />
          </>
      );
    },
  });

  const showScheduleDetailsModal = () => {
    setIsModalOpen(true);
    setModalType(VIEW_SCHEDULE);
  };

  const showModalForType = () => {
    switch(modalType) {
      case DELETE_SCHEDULE:
        return <SimpleConfirmModal
          open={modalType === DELETE_SCHEDULE}
          showClose={true}
          headerTitle="Delete Schedule"
          confirmText="Delete"
          cancelDataTag="cancelDeleteSchedule"
          confirmDeleteDataTag="confirmDeleteSchedule"
          onCloseClicked={()=> {
            setModalType('');
            setIsModalOpen(false);
          }}
          onConfirm={async () => {
            if (schedule) {
              await scheduleAPI.deleteSchedule(schedule);
              setModalType('');
              setIsModalOpen(false);
              setIsLoading(true);
              fetchSchedules();
            }
            return false;
          }}
        >
          <Typography variant="subtitle1">
            {schedule?.name}
          </Typography>
          <Typography>
            Are you sure you want to delete this selected schedule?
          </Typography>
        </SimpleConfirmModal>;

      case VIEW_SCHEDULE:
        return <ViewScheduleModal schedule={schedule} isModalOpen={isModalOpen} closeModalCb={closeModalCb}/>;
      case REASSIGN_OWNER:
        return <ReassignOwnerModal
          open={modalType === REASSIGN_OWNER}
          schedule={schedule}
          onCloseClicked={()=> {
            setModalType('');
            setIsModalOpen(false);
          }}
          //@ts-ignore
          onConfirm={async (user) => {
            const scheduleId = schedule?.id;
            if (scheduleId) {
              await scheduleAPI.reassignOwner(scheduleId, user.user_id);
              setModalType('');
              setIsModalOpen(false);
              setIsLoading(true);
              await fetchSchedules();
              return true;
            }
            return false;
          }}
        />;

    }

  };

  let filterSchedule = function(schedule){
    /**
     * Apply client-side filtering
     * */
    return true;
  };

  let handleScheduleFilter = (): any[] => {
    /**
     * Provides the list of schedules after filtering
     */
    return schedules
        ?.map((schedule: IReportScheduleDefinition) => {
          return {
            name: `${schedule.name}`,
            id: schedule.id,
            dashboard_look_id: schedule.dashboard_id ? schedule.dashboard_id : schedule.look_id,
            is_dashboard: !!schedule.dashboard_id,
            user_id: schedule.user_id,
            user: schedule.user,
            type: schedule.type,
            schedule: schedule.crontab,
            timezone: schedule.timezone,
            destinations: schedule.destinations,
            raw: schedule,
          };
        })
        .filter((el) => {
          // client side filtering
          return filterSchedule(el);
        })
        // alphabetically sort by name field
        .sort((a,b) => a.name.localeCompare(b.name));
  };

  return (
    <>
      <Fragment>
        <GridRow justifyContent={'space-between'}>
          <Typography variant="h4">Schedules</Typography>
        </GridRow>
        <Spacer size={16} direction="vertical" />
      </Fragment>

      <DataTable
        className={classes.main}
        autoHeight
        allowSelection={false}
        loading={isLoading}
        rowHeight={68}
        rows={handleScheduleFilter()}
        columns={scheduleColumns}
        onRowClick={handleRowClick}
      />
      {flags?.[getFlag(FFlags.ScheduleAdminGridMoreActions)] && modalType ? showModalForType() : null}
    </>
  );
};

export default SchedulesGrid;
